import Vue from 'vue'
import Router from 'vue-router'

const H5 = resolve => require(['@/views/h5'], resolve)

Vue.use(Router)

const vueRouter = new Router({
  routes: [
    {
      path: '/',
      name: 'H5',
      component: H5
    }]
  })

export default vueRouter